#header-image {
  background-image: url(./images/headerImage.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45vw;
  width: 100%;
  max-height: 600px;
}

#header-text {
  font-size: 7vw;
}

#header-sub-text {
  font-size: 3vw;
}

#header-content {
  margin-top: 25%;
}

#h2-responsive {
  font-size: 4vw;
  color: white;
}

#category-header {
  background-color: #ff7f50;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 48px;
  z-index: 1;
}

#card-grid {
  padding-top: 32px;
  padding-bottom: 32px;
}

#card-section {
  margin-left: 5%;
}

#resume-comment {
  display: flex;
  font-weight: 400;
  text-align: left;
  display: inline;
}

#comment-slashes {
  color: #ff7f50;
  display: inline;
}

#comment-container {
  margin-bottom: 3px;
  max-width: 500px;
}

#resume-name {
  text-align: right;
}

#responsive-modal {
  max-width: 50%;
}

.card-link {
  text-decoration: none;
  color: black;
}

#resume-sub-category {
  max-width: 700px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  #header-image {
    background-image: url(./images/headerImage_mobile.png);
  }

  #card-title {
    text-align: center;
  }

  #resume-comment {
    text-align: center;
  }

  #resume-name {
    text-align: center;
  }

  #resume-content {
    padding-bottom: 32px;
  }

  #header-text {
    font-size: 8vw;
  }

  #header-sub-text {
    font-size: 4vw;
  }

  #header-content {
    margin-top: 25%;
  }

  #h2-responsive {
    font-size: 6vw;
  }

  #responsive-modal {
    max-width: 70%;
  }

  #card-section {
    margin-left: 0;
  }
}

/* max width for scaling text, etc */
@media only screen and (min-width: 1280px) {
  #header-content {
    margin-top: 22%;
  }

  #header-text {
    font-size: 5.55em;
  }

  #header-sub-text {
    font-size: 2.35em;
  }

  #h2-responsive {
    font-size: 3em;
  }

  #responsive-modal {
    max-width: 25%;
  }

  #card-section {
    margin-left: 10%;
  }
}
